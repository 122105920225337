import { useState } from 'react';
import { Button, Space, Typography } from 'antd';

const LocationDisplay = ({ position }: { position: { latitude: number; longitude: number } }) => {
  const [location, setLocation] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchLocation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.rkcgps.net/reverse?lat=${position.latitude}&lon=${position.longitude}&format=json`,
      );
      const data = await response.json();

      if (data.address) {
        const { road, town, city, municipality, county, state, region } = data.address;
        const formattedLocation = [
          road,
          town || city || municipality || county, // Pick the most relevant town-level field
          state || region, // Pick state or region
        ]
          .filter(Boolean)
          .join(', '); // Join non-empty values with a comma

        setLocation(formattedLocation || 'Location not found');
      } else {
        setLocation('Location not found');
      }
    } catch (error) {
      setLocation('Error fetching location');
    }
    setLoading(false);
  };

  return (
    <div style={{ maxWidth: 324 }}>
      <Typography.Text type="secondary">
        Location: {location ? location : `${position.latitude}, ${position.longitude}`}
      </Typography.Text>
      <Space style={{ marginTop: 8 }}>
        {!location ? (
          <Button size="small" type="link" onClick={fetchLocation} loading={loading}>
            Show Address
          </Button>
        ) : (
          <Button size="small" type="link" onClick={() => setLocation(null)}>
            Show Raw Location
          </Button>
        )}
      </Space>
    </div>
  );
};

export default LocationDisplay;
